
import React from 'react';
import { Link } from 'gatsby'
import Apples from '../../assets/apples.jpg'
import Exigent from '../../assets/ExigentCapitalLogo.jpg'
import LinkedIn from '../../assets/linkedin1.png'

const LinkedInIcon = () => (
  <div className="w-20 mt-3">
    <a href="https://www.linkedin.com/company/exigentcapitalgroup/"><img src={LinkedIn} alt="linked in" /></a>
    
  </div>
)

const AppleImage = () => (
  <div className="mt-2 w-20 ml-24">
    <Link to="/#header">
    <img src={Apples} alt="apple image" />
    </Link>
  </div>
)

const ExigentLogo = () => (
  <div className="mt-10 mx-auto" style={{
    width:"7rem"
  }}>
    <a href="https://exigent.capital">
    <img src={Exigent} alt="logo" />
    </a>
  </div>
)

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    {/* <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5 text-base">Exigent722 is being established as Israel’s leading multi-family office catering to the full breadth of needs for the returning Oleh Community.</p>
      </div>
      <div className="flex-1 px-3">
        <ExigentLogo />
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://codebushi.com">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="https://codebushi.com">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <AppleImage className="" />
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://dev.to/changoman">Dev.to</a>
          </li>
          <li>
            <a href="https://twitter.com/HuntaroSan">Twitter</a>
          </li>
          <li>
            <a href="https://github.com/codebushi/gatsby-starter-lander">GitHub</a>
          </li>
        </ul>
      </div>
    </div> */}
  </footer>
);

export default Footer;

// const ExigentLogo = () => (
//   <div className="mt-4" style={{
//     width:"7rem"
//   }}>
//     <a href="https://exigent.capital">
//     <img src={Exigent} alt="logo" />
//     </a>
//   </div>
// )

// const Footer = () => (
//   <footer className=" mx-auto py-16 px-3 mt-48 mb-8  text-gray-900">
//     <div className="flex">
//       <div className="flex-1 px-3 w-1/3">
//         <h2 className="text-lg font-semibold ">About Us</h2>
//         <p className="mt-2 text-lg mr-10">Exigent722 is being established as Israel’s leading multi-family office catering to the full breadth of needs for the returning Oleh Community. </p>
//       </div>
//       <div className="mt-6 w-1/3 flex-1" style={{
//         paddingLeft:'16%'
//       }}>
//         <h2 className="text-lg font-semibold"></h2>
//         <ul className="leading-loose">
//           <li>
//             <ExigentLogo />
//             {/* <a href="https://exigent.capital">Exigent Capital</a> */}
//           </li>
//           {/* <li>
//             <LinkedInIcon />
//           </li> */}
//           {/* <li>
//             <a href="https://github.com/codebushi/gatsby-starter-lander">GitHub</a>
//           </li> */}
//         </ul>
//       </div>
//       <div className="w-1/3 flex-1">
//         <AppleImage />
//       </div>
     
//       {/* <div className="flex-1 px-3">
//         <h2 className="text-lg font-semibold w-1/3">Links</h2>
//         <ul className="mt-4 leading-loose">
//           <li>
//             <a href="">Terms &amp; Conditions</a>
//           </li>
//           <li>
//             <a href="">Privacy Policy</a>
//           </li>
//         </ul>
//       </div> */}
      
//     </div>
//   </footer>
// );

// export default Footer;
